import { INFRA_MAPPING } from './constant';
export function detectEnv() {
  const host = window?.location?.host;
  for (const [key, value] of Object.entries(INFRA_MAPPING)) {
    if (value === host) {
      return key;
    }
  }
  return 'local';
}
