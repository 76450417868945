import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { detectEnv } from './utils/utils';

const customInit = (dsn: string, options: object) => {
  return Sentry.init({
    dsn: dsn,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['localhost'],
      }),
    ],
    tracesSampleRate: 1.0,
    environment: detectEnv(),
    ...options,
  });
};
// re-export everything
export * from '@sentry/react';
export { customInit as init };
